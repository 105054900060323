import isExternalURL from '../../vendor/imarc/inkwell/resources/scripts/modules/linkObserver';
import convertToLocalTime from '../../vendor/imarc/inkwell/resources/scripts/modules/datetimeHandler';

const timeElements = [...document.querySelectorAll('time')];

window.addEventListener('content-populated', () => {
    const anchors = document.querySelectorAll('a');

    if (anchors.length) {
        anchors.forEach(anchor => {
            if (isExternalURL(anchor)) {
                anchor.classList.add('js-external');
            }
        });
    }

    timeElements.forEach(convertToLocalTime);
});
